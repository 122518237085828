<template>
  <div class="mobile-navbar">
    <ul class="mobile-navbar__list">
      <li class="mobile-navbar__list--item">
        <a class="link">
          <a-dropdown>
            <router-link to="/discover" class="link">Discover</router-link>
            <template #overlay>
              <a-menu class="mobile-navbar-dropdown">
                <a-menu-item key="1"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming Soon</template>
                    Topic Heatmap *
                  </a-tooltip></a-menu-item
                >
                <a-menu-item key="2"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming Soon</template>
                    Topic Timeline *
                  </a-tooltip></a-menu-item
                >
                <a-menu-item key="2"
                  ><a-tooltip class="link-tooltip" placement="right">
                    <template #title>Coming Soon</template>
                    Source Analytics *
                  </a-tooltip></a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </a>
      </li>
      <li class="mobile-navbar__list--item">
        <a class="link">
          <a-dropdown>
            <router-link to="/glocal-feed" class="link">News Feed</router-link>
          </a-dropdown>
        </a>
      </li>
      <!-- <li class="mobile-navbar__list--item">
        <a class="link">
          <a-tooltip class="link-tooltip">
            <template #title>Coming Soon</template>
            Publish *
          </a-tooltip>
        </a>
      </li> -->
      <!-- <li class="mobile-navbar__list--item"> -->
        <!-- <router-link to="/engage" class="link">Impact</router-link> -->
        <!-- <a-tooltip class="link-tooltip"> -->
          <!-- <template #title>Coming Soon</template> -->
          <!-- Impact * -->
        <!-- </a-tooltip> -->
      <!-- </li> -->
    <div class="mobile-navbar__list--buttons">
      <a-tooltip placement="bottom">
        <template #title>
            <h1>Requesting a new source?</h1>
            <p>In Glocal you can request more publication sources if you do not find them in our research source lists.</p>
        </template>
      </a-tooltip>
      <a-button
        class="mobile-navbar__list--btn"
        @click="visibleInviteToGlocalModal()"
      >
        <img src="@/assets/icons/forward_to_inbox.svg" alt="icon" class="contact-icon">
        Invite Contacts
      </a-button>
    </div>
    </ul>
  </div>

  <invite-to-glocal 
      :visibleModal="inviteToGlocalModal"
      @closeModal="closeInviteToGlocalModal">
  </invite-to-glocal>
</template>

<script>
import InviteToGlocal from "../BaseComponents/InviteToGlocal.vue"


export default {
  components: {
    InviteToGlocal
  },
  data() {
    return {
      requestNewsSourceModal: false,
      visibleSubscriptionModal: false,
      inviteToGlocalModal: false
    };
  },
  computed: {
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  methods: {
    closeRequestNewsSourceModal() {
      this.requestNewsSourceModal = false;
    },
    visibleInviteToGlocalModal() {
      if (this.isAuthenticatedUser) {
        this.inviteToGlocalModal = true
      } else {
        this.visibleSubscriptionModal = true;
      }
    },
    closeInviteToGlocalModal() {
      this.inviteToGlocalModal = false
    }
  },
};
</script>

<style lang="scss">
.mobile-navbar {
  top: 8rem;
  position: absolute;
  background-color: $color-white;
  z-index: 444;
  right: 0;
  width: 100%;
  &__list {
    margin-bottom: 0;
    &--item {
      padding: 1.5rem;
      text-align: center;
      list-style-type: none;
      border-bottom: 2px solid #eee;
      .link {
        font-size: 1.6rem;
        font-family: $font-secondary;
        color: $color-black;
      }
      .link-tooltip {
         font-size: 1.6rem;
      }
    }
    &--buttons {
      display: flex;
      flex-direction: column;
      .ant-btn {
        color: $color-white;
        font-family: $font-primary-medium;
        font-size: 1.6rem;
        background-color: $color-primary;
        padding: 0 2rem;
        height: 4.5rem;
        width: 20rem;
        margin: 2rem auto;
        line-height: normal;
        border: none;
        outline: none;
        text-align: center;
        
        span {
          line-height: 1.8rem;
        }
        .plus-icon {
          width: 1.4rem;
          height: auto;
          margin-right: 1.4rem;
          margin-bottom: 0.35rem;
        }
        .contact-icon {
          width: 2rem;
          height: auto;
          margin-right: 0.9rem;
        }
        &:hover,
        &:focus {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}
</style>
