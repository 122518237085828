<template>
  <a-menu>
      <a-menu-item data-cy="profilePageButton" key="1" @click="goToProfile">
        <a><i class="list-icon icon-account_circle"></i>
          Your Profile</a>
      </a-menu-item>
    <!-- <a-menu-item key="2">
      <i class="list-icon icon-download_arrow"></i>
      <a>Published Articles</a>
    </a-menu-item> -->
    <!-- <a-menu-item key="3">
      <img
        class="icon list-icon icon-text"
        src="../../assets/icons/text-box.svg"
      />
      <a>Drafts</a>
    </a-menu-item> -->
    <router-link data-cy="settingsPageButton" v-if="showOnSubscription" :to="`/user/${userId}/settings`">
      <a-menu-item key="4">
        <a><i class="list-icon icon-settings_filled"></i>
          Settings</a>
      </a-menu-item>
    </router-link>
    <a data-cy="logout" @click="logout">
      <a-menu-item key="5">
       <a><img
            class="icon list-icon icon-logout"
            src="../../assets/icons/logout.svg"
          />
          Logout</a>
      </a-menu-item>
    </a>
  </a-menu>
</template>
<script>
import Cookies from "js-cookie";
import StorageService from "@/services/storage.service";

export default {
  props: {
    showOnSubscription:{
      type: Boolean,
      default: true
    }
  },
  computed:{
    userId(){
      return this.$store.getters['auth/getUserId'];
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
  },
  methods:{
    async logout(){
      try{
        await this.$store.dispatch('auth/logout')
        .then(()=>{
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          Cookies.remove("user_id");
          Cookies.remove("interests")
          Cookies.remove("user")
          
          this.$router.replace('/');
        })
      }
      catch(err){
        console.log(err.response.data);
      }
    },
    goToProfile() {
      this.$router.push({
        path: `/user/${this.userId}/`,
        query: { tab: this.getUserInfo.account_type==="organization"?'photo':'experience' },
      });
      this.getUserInformation(this.userId)
    },
    async getUserInformation(userId) {
      const payload = {
        user_id: userId,
      };
      try {
        await this.$store.dispatch("profile/fetchUserProfile", payload);
        await this.$store.dispatch("profile/loadExperiences", payload);
        await this.$store.dispatch("profile/getAddedLanguages", payload);
        await this.$store.dispatch("profile/fetchPhotoResume", payload);
        
      } catch (err) {
        console.log(err);
      }
    },
  }
};
</script>
<style lang="scss">
.ant-dropdown-menu{
  a{
    display: block;
  }
}
.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  font-family: $font-secondary;
  padding: 0.5rem 1rem;
}
.list-icon {
  color: $color-primary;
  font-size: 2.5rem;
  margin-right: 1rem;
  width: 19%;
}
.list-icon.icon-download_arrow {
  transform: rotate(180deg);
}
.list-icon.icon-logout {
  color: #be0000;
  fill: #be0000;
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg)
    brightness(94%) contrast(115%);
  margin-left: 0.3rem;
}
.list-icon.icon-text {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(246deg)
    brightness(87%) contrast(156%);
}
.logout-btn {
  color: #be0000 !important;
}
</style>